import { Playground, Props } from 'docz';
import { SynapsoftFront } from "../../../../../src/synapsoft/index.tsx";
import { SynapsoftBack } from "../../../../../src/synapsoft/index.tsx";
import * as React from 'react';
export default {
  Playground,
  Props,
  SynapsoftFront,
  SynapsoftBack,
  React
};